const LOCAL_API_URL = "http://localhost:8080";
const PROD_API_URL = "https://data-api.ems-inc.ca";
const DEFAULT_API_URL = process.env.REACT_APP_ENV === "production" ? PROD_API_URL : LOCAL_API_URL;
const DEVELOPMENT_URL_APP =
   process.env.REACT_APP_ENV === "production"
      ? "https://inventory.ems-inc.ca"
      : "http://localhost:3000";
const IMAGE_EXPIRED_DURATION = 84600;
const CALIBRATION = "CALIBRATION";
const RED_GREEN = "RG";

// const ZOHO_SCOPE = "ZohoInventory.compositeitems.CREATE,ZohoInventory.compositeitems.READ,ZohoInventory.compositeitems.DELETE,ZohoInventory.items.READ,ZohoInventory.items.UPDATE,ZohoInventory.items.CREATE,ZohoInventory.settings.READ"
const ZOHO_SCOPE =
   "ZohoInventory.compositeitems.CREATE,ZohoInventory.compositeitems.DELETE,ZohoInventory.compositeitems.READ,ZohoInventory.items.READ,ZohoInventory.items.UPDATE,ZohoInventory.items.CREATE,ZohoInventory.settings.READ";
const ZOHO_PER_PAGE = 10;
const ZOHO_ORGANIZATION_ID = 762560410;
const ZOHO_ACCESS_TOKEN_API = "https://accounts.zoho.com/oauth/v2/auth";
const ZOHO_INVENTORY_API = `https://inventory.zoho.com/api/v1/items?organization_id=${ZOHO_ORGANIZATION_ID}&sort_column=last_modified_time&sort_order=D&status=active&per_page=${ZOHO_PER_PAGE}`;
const ZOHO_LOGIN_API = `${ZOHO_ACCESS_TOKEN_API}?client_id=:client_id&response_type=token&scope=:scope&redirect_uri=${DEVELOPMENT_URL_APP}/dashboard`;
const ZOHO_COMPOSITE_ITEM = "https://inventory.zoho.com/api/v1/compositeitems/:item_id";
const ZOHO_CREATE_BUNDLE_API = "https://inventory.zoho.com/api/v1/bundles";
const ZOHO_PROXY_SERVER = `${DEFAULT_API_URL}/inventory/items/zohoItems`;
const ZOHO_REFRESH_TOKEN_API = `https://accounts.zoho.com/oauth/v2/auth?scope=:scope&client_id=:client_id&state=testing&response_type=code&redirect_uri=${DEVELOPMENT_URL_APP}/dashboard&access_type=offline&prompt=consent`;
const ZOHO_GET_TOKEN_API = "https://accounts.zoho.com/oauth/v2/token";
const ZOHO_BUNDLING_HISTORY = `https://inventory.zoho.com/api/v1/bundles?composite_item_id=:composite_item_id`;
const ZOHO_CURRENT_USER_API = "https://www.zohoapis.com/inventory/v1/users/me";
const ZOHO_BUNDLE_DELETE_API = `https://www.zohoapis.com/inventory/v1/bundles/:bundle_id?organization_id=${ZOHO_ORGANIZATION_ID}`;

const ZOHO_SOIL_SENSE_3_AIR = "2857783000006636116";
const ZOHO_SOIL_SENSE_3_NO_AIR = "2857783000006636002";
const ZOHO_DRY_WATER_1_NO_AIR = "2857783000006636059";

const ZOHO_SENSOR_HOUSING_NEW = "2857783000007075007";
const ZOHO_SENSOR_BOARDS_NEW = "2857783000007072188";
const ZOHO_SOIL_SENSE_3_NEW = "2857783000007075070";
const ZOHO_SOIL_SENSE_2_NEW = "2857783000012719007";
const ZOHO_WELL_SENSOR_NEW = "2857783000007244002";
const ZOHO_OPTIMIZER = "2857783000009438644";

const ZOHO_UNDERGROUND_BATTERY = "2857783000006512371";
const ZOHO_UNDERGROUND_TCM = "2857783000006518006";
const ZOHO_SOLAR_30W = "2857783000006512254";
const ZOHO_SOLAR_50W = "2857783000001287566";
const ZOHO_INSTACK_POWER = "2857783000009356035";
const ZOHO_AG_SENSE = "2857783000012281112";

const OPEN_WEATHER_API = "https://api.openweathermap.org/data/2.5/forecast?units=metric";
const LOGIN_API_URL = "/login";
const TOP_INVENTORY_API_URL = "/inventory/items/getImportantItems";
const LOW_STOCK_INVENTORY_API_URL = "/inventory/items/getLowStockData/:limit";
const UPDATE_INVENTORY = "/inventory/items/updateItems";
const SITES_API_URL = "/sites";
const SITES_ALL_API_URL = "/sites/all/not-internal";
const SITES_STATUS_API_URL = "/sites/status/:starttimestamp/:endtimestamp";
const NOTES_ADD_API = "/inventory/notes/add";
const NOTES_GET_API = "/inventory/notes/get/:limit";
const NOTES_SEARCH_API = "/inventory/notes/search/:limit";
const NOTES_IMAGES_API = "/inventory/notes/getImages";
const NOTES_UPDATE_API = "/inventory/notes/updateNotes";
const NOTES_GET_ONE_API = "/inventory/notes/specific/get/:id";
const REPORTS_GET_ONE_API = "/inventory/reports/specific/get/:id";
const CHECKLIST_ADD_API_URL = "/inventory/checklist/insertItems";
const CHECKLIST_UPDATE_API_URL = "/inventory/checklist/updateItems";
const CHECKLIST_GET_API = "/inventory/checklist/get/:limit";
const CHECKLIST_SEARCH_API = "/inventory/checklist/search/:limit";
const REPORT_ADD_API = "/inventory/reports/add";
const REPORT_SEARCH_API = "/inventory/reports/search/:limit";
const REPORT_PDF_API = "/inventory/reports/getReports";
const KPI_GET_API = "/sensor-boards/kpi/get/:test";
const KPI_PROVENANCE_API = "/sensor-boards/search/:id";
const KPI_FIELD_TEST_INSERT_API = "/sensor-boards/field-test/add";
const UPDATE_SENSOR_STATE = "/sensor-boards/change-state/:id";
const REPORT_PDF_DELETE_API = "/inventory/reports/deleteReports";
const SAFETY_API = {
   NEW_FORM_ADD: "/safety/newSite/add",
   GET: "/safety/get/:limit",
   SEARCH: "/safety/search/:limit",
   IMAGES: "/safety/getImages",
};

const QA_CAL_INSERT_API_URL = "/inventory/qa/insertCaliberationData";
const QA_WATER_INSERT_API_URL = "/inventory/qa/insertWatertestData";
const QA_SENSOR_BOARDS_GET_API_URL = "/inventory/qa/getSensorBoards/:limit";
const QA_SENSOR_BOARDS_SEARCH_API_URL = "/inventory/qa/sensor-search/:type/:search";
const QA_ALL_SENSORS_GET_API_URL = "/inventory/qa/getSensorBoardsForQuantifiers/";
const QA_EQUIPMENT_COUNT_API_URL = "/inventory/qa/equipment_version/:type/:year";
const QA_QUANTIFIER_INSERT_API_URL = "/inventory/qa/insertQuantifierData";
const QA_QUANTIFIER_UPDATE_API_URL = "/inventory/qa/UpdateQuantifierData";
const QA_QUANTIFIER_DELETE_API_URL = "/inventory/qa/DeleteQuantifierData";
const QA_QUANTIFIERS_GET_API_URL = "/inventory/qa/quantifiers/:limit";
const QA_QUANTIFIERS_SEARCH_API_URL = "/inventory/qa/quantifiers-search/:type/:search";
const QA_SENSOR_BOARDS_UPDATE_API_URL = "/inventory/qa/UpdateSensorBoardData";
const QA_EQUIPMENT_BULK_INSERT_API_URL = "/inventory/qa/insertEquipmentDataBulk";
const QA_EQUIPMENT_INSERT_API_URL = "/inventory/qa/insertEquipmentData";
const QA_EQUIPMENT_VIEW_API_URL = "/inventory/qa/equipment/get/:limit";
const QA_EQUIPMENT_SEARCH_API_URL = "/inventory/qa/equipment/search/:search";

const SOIL_SENSE_3_SENSORS_AIR = "SOIL_SENSE_WITH_AIR_FITTINGS_3_SENSORS";
const SOIL_SENSE_3_SENSORS_NO_AIR = "SOIL_SENSE_WITHOUT_AIR_FITTINGS_3_SENSORS";
const SOIL_SENSE_DRY_WATER = "DRY_WATER_SOIL_SENSE_WITHOUT_AIR_FITTINGS";

const SOIL_SENSE_DRY_WATER_NEW = "DRY_WATER_SOIL_SENSE_V0";
const SOIL_SENSE_3_SENSORS_NEW = "SOIL_SENSE_3_SENSORS_V0";
const SOIL_SENSE_2_SENSORS_NEW = "SOIL_SENSE_2_SENSORS_V0";
const OPTIMIZER_1_SENSOR = "OPTIMIZER_1_SENSOR";
const AG_1_SENSOR = "AG_1_SENSOR";

const SENSOR_OLD = "OLD_FORM_FACTOR";
const SENSOR_NEW = "NEW_FORM_FACTOR_V0";

const PAGE_DASHBOARD = "/dashboard";
const PAGE_PACKET_LOSS = "/data/packet-loss";
const PAGE_SENSOR_LOCATION = "/data/sensor-location";
const PAGE_SENSOR_BOARDS_ADD = "/sensor-boards/add";
const PAGE_SENSOR_BOARDS_VIEW = "/sensor-boards/view";
const PAGE_QUANTIFIER_ADD = "/quantifier/add";
const PAGE_QUANTIFIER_VIEW = "/quantifier/view";
const PAGE_QUANTIFIER_REPORT = "/quantifier/report";
const PAGE_FIELD_NOTES_ADD = "/field/notes/add";
const PAGE_FIELD_NOTES_VIEW = "/field/notes/view";
const PAGE_FIELD_NOTES_CHECKLIST_ADD = "/field/checklist/add";
const PAGE_FIELD_NOTES_CHECKLIST_VIEW = "/field/checklist/view";
const PAGE_FIELD_SAFETY_NEW_SITE = "/field/safety/new-site";
const PAGE_FIELD_SAFETY_VIEW = "/field/safety/view";
const PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT = "/field/safety/journey-management";
const PAGE_EQUIPMENT_VIEW = "/equipment/view";
const PAGE_FIELD_REPORTS_ADD = "/field/reports/add/:notesId";
const PAGE_FIELD_REPORTS_VIEW = "/field/reports/view";
const PAGE_FIELD_REPORTS_ADD_CHECK = "/field/reports/add/";
const PAGE_SENSOR_KPI = "/data/kpi";

const FIELD_PERSONAL = [
   "Alejandro",
   "Brandon",
   "Chais",
   "Chelsea",
   "Dave",
   "Emile"
];

const STATE = {
   CALIBRATION: "CALIBRATION",
   PRE_PRODUCTION_RG: "PRE_PRODUCTION_RG",
   POST_PRODUCTION_RG: "POST_PRODUCTION_RG",
   FIELD_RG: "FIELD_RG",
   IN_FIELD: "IN_FIELD",
};

const SAFETY = {
   NEW_SITE: "NEW_SITE_SPECIFIC",
   JOURNEY_MANAGEMENT: "JOURNEY_MANAGEMENT",
};
const CONSTANTS = {
   FIELD_PERSONAL,
   LOGIN_API_URL,
   DEVELOPMENT_URL_APP,
   IMAGE_EXPIRED_DURATION,
   CALIBRATION,
   RED_GREEN,
   ZOHO_LOGIN_API,
   DEFAULT_API_URL,
   TOP_INVENTORY_API_URL,
   NOTES_ADD_API,
   NOTES_GET_API,
   NOTES_SEARCH_API,
   NOTES_IMAGES_API,
   NOTES_UPDATE_API,
   NOTES_GET_ONE_API,
   REPORTS_GET_ONE_API,
   CHECKLIST_ADD_API_URL,
   CHECKLIST_UPDATE_API_URL,
   CHECKLIST_GET_API,
   CHECKLIST_SEARCH_API,
   REPORT_ADD_API,
   REPORT_SEARCH_API,
   REPORT_PDF_API,
   REPORT_PDF_DELETE_API,
   LOW_STOCK_INVENTORY_API_URL,
   SOIL_SENSE_3_SENSORS_AIR,
   SOIL_SENSE_3_SENSORS_NO_AIR,
   SOIL_SENSE_DRY_WATER,
   SOIL_SENSE_DRY_WATER_NEW,
   SOIL_SENSE_3_SENSORS_NEW,
   SOIL_SENSE_2_SENSORS_NEW,
   OPTIMIZER_1_SENSOR,
   AG_1_SENSOR,
   ZOHO_OPTIMIZER,
   ZOHO_SCOPE,
   ZOHO_PROXY_SERVER,
   ZOHO_REFRESH_TOKEN_API,
   ZOHO_ACCESS_TOKEN_API,
   ZOHO_GET_TOKEN_API,
   ZOHO_INVENTORY_API,
   ZOHO_COMPOSITE_ITEM,
   ZOHO_CREATE_BUNDLE_API,
   ZOHO_SOIL_SENSE_3_AIR,
   ZOHO_SOIL_SENSE_3_NO_AIR,
   ZOHO_DRY_WATER_1_NO_AIR,
   ZOHO_CURRENT_USER_API,
   ZOHO_BUNDLE_DELETE_API,
   ZOHO_UNDERGROUND_BATTERY,
   ZOHO_UNDERGROUND_TCM,
   ZOHO_BUNDLING_HISTORY,
   ZOHO_SOLAR_30W,
   ZOHO_SOLAR_50W,
   ZOHO_INSTACK_POWER,
   ZOHO_SENSOR_HOUSING_NEW,
   ZOHO_SENSOR_BOARDS_NEW,
   ZOHO_SOIL_SENSE_3_NEW,
   ZOHO_SOIL_SENSE_2_NEW,
   ZOHO_WELL_SENSOR_NEW,
   ZOHO_AG_SENSE,
   UPDATE_INVENTORY,
   PAGE_DASHBOARD,
   PAGE_SENSOR_BOARDS_ADD,
   PAGE_SENSOR_BOARDS_VIEW,
   PAGE_PACKET_LOSS,
   PAGE_SENSOR_LOCATION,
   PAGE_QUANTIFIER_ADD,
   PAGE_QUANTIFIER_VIEW,
   PAGE_QUANTIFIER_REPORT,
   PAGE_FIELD_NOTES_CHECKLIST_ADD,
   PAGE_FIELD_NOTES_CHECKLIST_VIEW,
   PAGE_FIELD_NOTES_ADD,
   PAGE_FIELD_NOTES_VIEW,
   PAGE_EQUIPMENT_VIEW,
   PAGE_FIELD_REPORTS_ADD,
   PAGE_FIELD_REPORTS_VIEW,
   PAGE_FIELD_REPORTS_ADD_CHECK,
   PAGE_FIELD_SAFETY_NEW_SITE,
   PAGE_FIELD_SAFETY_VIEW,
   PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT,
   PAGE_SENSOR_KPI,
   SITES_API_URL,
   SITES_STATUS_API_URL,
   SITES_ALL_API_URL,
   SENSOR_OLD,
   SENSOR_NEW,
   OPEN_WEATHER_API,
   QA_CAL_INSERT_API_URL,
   QA_WATER_INSERT_API_URL,
   QA_SENSOR_BOARDS_GET_API_URL,
   QA_ALL_SENSORS_GET_API_URL,
   QA_EQUIPMENT_COUNT_API_URL,
   QA_EQUIPMENT_VIEW_API_URL,
   QA_EQUIPMENT_SEARCH_API_URL,
   QA_QUANTIFIER_INSERT_API_URL,
   QA_QUANTIFIERS_GET_API_URL,
   QA_QUANTIFIER_UPDATE_API_URL,
   QA_QUANTIFIER_DELETE_API_URL,
   QA_SENSOR_BOARDS_UPDATE_API_URL,
   QA_QUANTIFIERS_SEARCH_API_URL,
   QA_SENSOR_BOARDS_SEARCH_API_URL,
   QA_EQUIPMENT_BULK_INSERT_API_URL,
   QA_EQUIPMENT_INSERT_API_URL,
   KPI_GET_API,
   KPI_PROVENANCE_API,
   KPI_FIELD_TEST_INSERT_API,
   SAFETY_API,
   UPDATE_SENSOR_STATE,
   STATE,
   SAFETY,
};

export default CONSTANTS;
